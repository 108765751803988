<template>
  <v-row v-if="getDomainsPagination && getDomainsPagination.count > 0">
    <v-col
      v-if="getDomainsPagination.page > 1"
      class="flex-grow-0"
      @click="decreaseDomainsPage"
    >
      <v-btn
        min-width="50"
      >
        Prev
      </v-btn>
    </v-col>
    <v-col
      v-for="page in pageButtons" :key="`domains-pagination-${page}`"
      class="flex-grow-0"
    >
      <v-btn
        :color="getDomainsPagination.page === page ? 'accent' : ''"
        min-width="50"
        @click="setDomainsPage(page)"
      >
        {{ page }}
      </v-btn>
    </v-col>
    <v-col
      v-if="getDomainsPagination.page < getDomainsPagination.total_pages"
      class="flex-grow-0"
    >
      <v-btn
        min-width="50"
        @click="increaseDomainsPage"
      >
        Next
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'PaginationComp',
    data: () => ({
      
    }),
    watch: {
      getDomainsPage () {
        console.log('changed')
        this.$emit('refresh')
      }
    },
    computed: {
      ...mapGetters([
        'getDomainsPage',
        'getDomainsPagination',
      ]),
      pageButtons () {
        const showMax = 4
        const p = []

        for (let i = this.getDomainsPagination.page-showMax; i <= this.getDomainsPagination.page+showMax; i++){
          if (i > 0 && i <= this.getDomainsPagination.total_pages) {
            p.push(i)
          }
        }

        return p
      }
    },
    methods: {
      ...mapMutations([
        'setDomainsPage',
        'increaseDomainsPage',
        'decreaseDomainsPage'
      ])
    }
  }
</script>