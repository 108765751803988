import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        base: '#F4F4F4',
        primary: '#313131',
        secondary: '#616161',
        accent: '#0051c3',
        'main-border': '#999999',
        error: '#EF4135',
        success: '#00860F',
        warning: '#FFC107'
      }
    }
  }
})