<template>
  <div>
    <BaseTitle
      @refresh="refresh"
    >
      <template v-slot:title>
        Analytics
      </template>
      <template v-slot:actions>
        <v-col 
          v-if="getSocketAllFinished === 'done'"
          cols="auto"
          class="flex-grow-0 text-right"
        >
          <v-btn
            text
            @click="$store.dispatch('csvAnalyticsAllDownload', {bytesFormatted})"
          >
            CSV Export
          </v-btn>
        </v-col>
      </template>
    </BaseTitle>

    <v-row v-if="getDomains.length > 0" class="ma-0">
      <v-col cols="12">
        <Pagination
          @refresh="refresh"
        />
      </v-col>
      <v-col cols="12">
        <!-- TODO: some loading?-->
        <v-data-table
          dense
          :headers="analyticsTableHeaders"
          :items="getDomains"
          hide-default-footer
          disable-pagination
          disable-sort
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:top>
            <div class="d-flex flex-row justify-start pa-2">
              <v-btn
                class="mr-1"
                :color="timePeriod === '24h' ? 'accent' : 'gray'"
                small
                @click="timePeriod = '24h'"
              >
                24 h
              </v-btn>
              <v-btn
                :color="timePeriod === '30d' ? 'accent' : 'gray'"
                small
                @click="timePeriod = '30d'"
              >
                30 d
              </v-btn>
            </div>
          </template>
          <template v-slot:header.unique_visitors="{ header }">
            {{ `${header.text} (${timePeriod})` }}
          </template>
          <template v-slot:header.total_requests="{ header }">
            {{ `${header.text} (${timePeriod})` }}
          </template>
          <template v-slot:header.total_data_served="{ header }">
            {{ `${header.text} (${timePeriod})` }}
            <v-switch
                v-model="bytesFormatted"
                class="ma-0 ml-auto mr-1 pa-0"
                style="margin-left: auto;"
                color="accent"
                hide-details
                hint="aa"
                persistent-hint
                dense
              >
                <template v-slot:label>
                  <span class="caption">Format bytes</span>
                </template>
              </v-switch>
          </template>
          <template v-slot:body.prepend>
            <tr
            v-if="getDomains.some(d => { return d.busy === 'progress'})"
            >
              <td colspan="4">
                <v-progress-linear
                  indeterminate
                  color="accent"
                ></v-progress-linear>
              </td>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr :class="item.busy !== 'progress' ? (item.busy === 'error' ? 'analyticsError' : 'analyticsDone') : 'analyticsProgress'">
              <td>
                <v-progress-circular
                  v-if="item.busy === 'progress'"
                  class="mr-2"
                  size="12"
                  width="2"
                  indeterminate
                  color="secondary"
                />

                <v-icon
                  v-if="item.busy === 'error'"
                  class="ma-2"
                  color="error"
                >
                  fas fa-exclamation-triangle
                </v-icon>

                {{ item.name }}
              </td>
              <td>
                {{ item.analytics && item.analytics[timePeriod] ? item.analytics[timePeriod].unique_visitors : `${item.busy === 'error' ? 'error' : 'loading ...'}`}}
              </td>
              <td>
                {{ item.analytics && item.analytics[timePeriod] ? item.analytics[timePeriod].total_requests : `${item.busy === 'error' ? 'error' : 'loading ...'}`}}
              </td>
              <td>
                {{ item.analytics && item.analytics[timePeriod] ? (bytesFormatted ? humanize(item.analytics[timePeriod].total_data_served) : item.analytics[timePeriod].total_data_served) : `${item.busy === 'error' ? 'error' : 'loading ...'}`}}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <Pagination
          @refresh="refresh"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import BaseTitle from '@/components/BaseTitle.vue'
  import Pagination from '@/components/Pagination.vue'
  import { mapGetters } from 'vuex'

  export default {
    name: 'AnalyticsPage',
    data: () => ({
      timePeriod: '24h',
      bytesFormatted: true,
      analyticsTableHeaders: [
        {
          text: 'Zone',
          value: 'zone'
        },
        {
          text: 'Unique Visitors',
          value: 'unique_visitors'
        },
        {
          text: 'Total Requests',
          value: 'total_requests'
        },
        {
          text: 'Total Data served',
          value: 'total_data_served'
        }
      ]
    }),
    components: {
      BaseTitle,
      Pagination
    },
    computed: {
      ...mapGetters([
        'getDomains',
        'getShowProgress',
        'getSocketAllFinished'
      ])
    },
    methods: {
      refresh () {
        this.$store.dispatch('getDomains').then(() => {
          this.$store.dispatch('analytics')
        })
      },
      humanize (value) {
        return this.$utils.bytesToSize(value)
      }
    },

    created () {
      this.refresh()
    }
  }
</script>

<style>
tr.analyticsError td {
  color: var(--v-error-base);
}

tr.analyticsProgress td {
  color: var(--v-main-border-base);
}
</style>