<template>
  <v-row class="ma-0 align-center justify-space-between">
    <v-col cols="auto">
      <h1>
        <slot name="title"></slot>
      </h1>
    </v-col>
    <v-col v-if="hideRefresh !== 'true'" cols="auto" class="flex-grow-1">
      <v-progress-circular
        v-if="getShowProgress"
        size="48"
        width="4"
        indeterminate
        color="accent"
      />

      <v-btn
        v-else
        icon
        @click="$emit('refresh')"
      >
        <v-icon
          color="accent"
        >
          fas fa-sync
        </v-icon>
      </v-btn>
    </v-col>
    <!-- TODO: hide CSV export on domain list reload -->
    <slot name="actions"></slot>
    <v-col v-if="hideSearch !== 'true'" cols="4">
      <v-text-field
        v-model="search"
        label="Search"
        outlined
        hide-details
        dense
        @keydown.enter="$store.dispatch('getDomains')"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'BaseTitle',
    props: ['hideRefresh', 'hideSearch'],
    data: () => ({
      searchTimer: null,
    }),
    computed: {
      ...mapGetters([
        'getShowProgress'
      ]),
      search: {
        get () {
          return this.$store.getters.getSearch
        },
        set (newSearch) {
          this.$store.commit('setSearch', newSearch)

          if (newSearch.match(/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/)) {
            this.$store.commit('setDomainsPage', 1)
          }

          if (newSearch.length >= 3 || newSearch.length === 0) {
            if (this.searchTimer) { clearTimeout(this.searchTimer) }

            this.searchTimer = setTimeout(() => {
              this.$emit('refresh')
            }, 500)
          }
        }
      }
    }
  }
</script>