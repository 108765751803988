<template>
  <v-app>
    <div
      v-if="alerts.length > 0"
      class="alertsGlobal"
    >
      <v-alert
        v-for="(alert, i) in visibleAlerts"
        :key="`globalAlerts-${i}`"
        dense
        dismissible
        :type="alert.type || 'info'"
      >
        {{ alert.message }}
      </v-alert>  
    </div>
    <BaseHeader v-if="getAuthorized" />

    <v-main class="text-left">
      <v-container fluid fill-height class="align-start">
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import BaseHeader from '@/components/BaseHeader.vue'
  import { mapGetters } from 'vuex'
  import eventBus from '@/lib/eventBus'

  export default {
    name: 'App',
    components: {
      BaseHeader
    },
    data: () => ({
      alerts: [],
      maxAlerts: 10,
      alertsStayFor: 3000,
      timeout: null
    }),
    computed: {
      ...mapGetters([
        'getAuthorized'
      ]),
      visibleAlerts () {
        return this.alerts.slice(this.alerts.length - this.maxAlerts)
      }
    },
    created () {
      eventBus.$on('alertGlobal', (alert) => {
        this.alerts.push(alert)

        if (this.timeout) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(() => {
          this.alerts = []
        }, this.alertsStayFor*this.visibleAlerts.length)
      })
    }
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.alertsGlobal {
  position:absolute;
  top:10px; 
  left: 10px;
  right: 10px;
  z-index: 9999;
}

.container > * { flex-grow: 1 }
</style>
