<template>
  <div>
    <v-btn-toggle
      v-if="!item.exists || item.force"
      v-model="item.dnsConfiguration"
      mandatory
    >
      <v-btn
        x-small
      >
        Skip DNS records
      </v-btn>
      <v-btn
        x-small
      >
        Fetch existing DNS
      </v-btn>
      <v-btn
        x-small
      >
        Create new DNS and Rules
      </v-btn>
    </v-btn-toggle>

    <v-row
      v-if="item.dnsConfiguration === 2 && selectedRow === item.name"
      class="pt-3 align-center"
      no-gutters
    >
      <v-col cols="8">
        <v-text-field
          v-model="item.ip"
          hide-details
          dense
          label="IP"
          outlined
        />
      </v-col>
      <v-col cols="4">
        <v-switch
          v-model="item.proxied"
          label="Proxied"
          color="accent"
          class="ma-0 mt-3 ml-3"
        ></v-switch>
      </v-col>
      <v-col cols="12">
        <v-switch
          v-model="item.createWWWRecord"
          label="Create www CNAME record"
          color="accent"
          class="ma-0 mt-3"
        ></v-switch>
      </v-col>
      <v-col cols="12">
        <v-row
          class="pt-3 align-center"
          no-gutters
        >
          <v-col cols="12">
            Rules:
          </v-col>
          <v-col
            v-if="item.rules.length > 0" 
            cols="12"
          >
            <v-row
              v-for="(rule, i) in item.rules"
              :key="`${item.name}-rules-${i}`"
              class="pt-3 align-start"
            >
              <v-col cols="3">
                <v-text-field
                  v-model="rule.targets[0].constraint.value"
                  dense
                  label="Matching Url"
                  :hint="`Ex: http://${item.name}/*`"
                  persistent-hint
                  outlined
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="rule.actions[0].value.url"
                  dense
                  label="Destination Url"
                  :hint="`Ex: https://www.${item.name}/*`"
                  persistent-hint
                  outlined
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="rule.actions[0].value.status_code"
                  type="number"
                  dense
                  label="Status Code"
                  hint="301 or 302"
                  persistent-hint
                  outlined
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="rule.priority"
                  type="number"
                  dense
                  label="Priority"
                  hint="Ex: 1, 2 ... 5"
                  persistent-hint
                  outlined
                />
              </v-col>
              <v-col cols="2">
                <v-btn
                  class="mt-2"
                  color="error"
                  x-small
                  @click="item.rules.splice(i, 1)"
                >
                  Delete
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-btn
          color="accent"
          class="mt-3"
          depressed
          small
          @click="createRule(item)"
        >
          Add Page Rule
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'AddDomainsManual',
    props: ['initialItem', 'selectedRow'],
    data: () => ({
      item: {}
    }),
    computed: {
    },
    methods: {
      createRule (item) {
        item.rules.push({
          targets: [
            {
              target: "url",
              constraint: {
                operator: "matches",
                value: `${item.name}/*`
              }
            }
          ],
          actions: [
            {
              id: "forwarding_url",
              value: {
                url: "",
                status_code: 301
              }
            }
          ],
          priority: 1,
          status: "active"
        })
      }
    },

    created () {
      this.item = this.initialItem
    }
  }
</script>

<style>
</style>