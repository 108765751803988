<template>
  <div>
    <BaseTitle
      @refresh="refresh"
    >
      <template v-slot:title>
        Domains
      </template>
      <template v-slot:actions>
        <v-col 
          v-if="getSocketAllFinished === 'done'"
          cols="auto"
          class="flex-grow-0 text-right"
        >
          <v-btn
            text
            @click="$store.dispatch('csvTestAllDownload')"
          >
            CSV Export
          </v-btn>
        </v-col>
        <v-col 
          v-if="getSocketAllFinished !== 'done'"
          cols="auto"
          class="flex-grow-0 text-right"
        >
          <v-btn
            color="accent"
            depressed
            @click="$store.dispatch('testAll')"
          >
            Test All

            <v-progress-circular
              v-if="getSocketAllFinished === 'progress'"
              class="ma-2"
              size="24"
              width="2"
              indeterminate
              color="white"
            />
          </v-btn>

          <v-btn
            color="gray"
            depressed
            to="/test-domains"
          >
            Test Page
          </v-btn>
        </v-col>
      </template>
    </BaseTitle>

    <v-row v-if="getDomains.length > 0" class="ma-0">
      <v-col cols="12">
        <Pagination
        @refresh="refresh"
        />
      </v-col>
      <v-col cols="12" v-for="domain in getDomains" :key="domain.id">
        <v-card
            color="base"
          >
            <v-card-title class="text-h5 pb-1">
              <v-progress-circular
                v-if="domain.busy === 'progress'"
                class="ma-2"
                size="24"
                width="2"
                indeterminate
                color="accent"
              />

              <v-icon
                v-if="domain.busy === 'done'"
                class="ma-2"
                color="green"
              >
                fas fa-check
              </v-icon>

              <v-icon
                v-if="domain.busy === 'error'"
                class="ma-2"
                color="error"
              >
                fas fa-exclamation-triangle
              </v-icon>

              <v-menu
                open-on-hover
                bottom
                offset-x
                offset-y
                close-delay="100"
                nudge-right="10"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="ma-0 pa-2"
                    text
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    v-on:mouseenter="openTooltip(domain.id)"
                  >
                    {{domain.name}}
                  </v-btn>
                </template>
                <div
                  class="menu-tooltip pa-2"
                >
                  <v-progress-circular
                    v-if="loading && !getShowProgress"
                    size="12"
                    width="2"
                    indeterminate
                    color="white"
                  />

                  <v-data-table
                    v-else
                    dense
                    :headers="recordsTableHeaders"
                    :items="domain.dnsRecords"
                    hide-default-footer
                    disable-pagination
                    disable-sort
                    item-key="id"
                    class="elevation-1"
                  >
                  </v-data-table>
                </div>
              </v-menu>

              <v-chip
                class="px-2 py-0 ma-2"
                :color="domain.status === 'active' ? 'green' : 'orange'"
                x-small
                text-color="white"
              >
                {{domain.status}}
              </v-chip>

              <span class="overline">NS: </span>
              
              <v-chip
                v-for="(ns, i) in domain.name_servers" :key="`domain_ns_${i}`"
                class="px-2 py-0 ma-1"
                color="gray"
                x-small
                text-color="primary"
              >
                {{ns}}
              </v-chip>
            </v-card-title>

            <v-card-actions class="pa-4 pt-1">
              <v-btn
                depressed
                text
                small
                color="grey"
                :href="`${getCloudflareDashboardUrl}/${domain.account.id}/${domain.name}/dns`"
                target="_blank"
              >
                DNS records

                <v-icon
                  class="ml-2"
                  small
                  color="accent"
                >
                  mdi-open-in-new
                </v-icon>
              </v-btn>

              <v-btn
                depressed
                small
                color="accent"
                @click="openTestPannel(domain.id, domain.name)"
              >
                Test
              </v-btn>
            </v-card-actions>

            <div v-if="getSelectedDomainId === domain.id && pannel === 'test'">
              <v-progress-linear
                v-if="loading && !getShowProgress"
                indeterminate
                color="accent"
              ></v-progress-linear>

              <v-data-table
                dense
                :headers="testTableHeaders"
                :items="getDomainTestingUrlsById(domain.id)"
                hide-default-footer
                disable-pagination
                disable-sort
                item-key="url"
                class="elevation-1"
              >
                <template v-slot:top>
                  <div class="d-flex flex-row justify-end pa-1">
                    <v-btn
                      depressed
                      small
                      color="gray"
                      @click="closePannel('test', domain.id)"
                    >
                      Close
                    </v-btn>
                  </div>
                </template>
                <template v-slot:item.code="{ item }">
                  <v-chip
                    v-if="item.code"
                    class="px-2 py-0 ma-0"
                    :color="urlStatusColor(item.code)"
                    x-small
                    text-color="primary"
                  >
                    {{item.code}}
                  </v-chip>
                  <v-progress-circular
                    v-else
                    size="12"
                    width="2"
                    indeterminate
                    color="secondary"
                  />
                </template>
              </v-data-table>
            </div>
          </v-card>

      </v-col>
      <v-col cols="12">
        <Pagination
          @refresh="refresh"
        />
      </v-col>
    </v-row>

  </div>
</template>

<script>
  import BaseTitle from '@/components/BaseTitle.vue'
  import Pagination from '@/components/Pagination.vue'
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'DomainsList',
    components: {
      BaseTitle,
      Pagination
    },
    data: () => ({
      pannel: '',
      loading: false,
      recordsTableHeaders: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Content',
          value: 'content'
        },
        {
          text: 'Proxy',
          value: 'proxied'
        },
        {
          text: '',
          value: 'actions'
        }

      ],
      testTableHeaders: [
        {
          text: 'Url',
          value: 'url'
        },
        {
          text: 'Status',
          value: 'code'
        },
        {
          text: 'Redirect',
          value: 'redirect'
        }
      ]
    }),
    computed: {
      ...mapGetters([
        'getProxyUrl',
        'getSessionEmail',
        'getSessionApikey',
        'getDomains',
        'getSelectedDomainId',
        'getDomainTestingUrlsById',
        'getSocketAllFinished',
        'getCloudflareDashboardUrl',
        'getShowProgress'
      ])
    },
    methods: {
      ...mapMutations([
        'setSelectedDomainId',
        'setDomainDNSRecords',
        'setDomainTestingUrls'
      ]),
      refresh () {
        this.$store.dispatch('getDomains')
      },
      closePannel (pannel, domainId) {
        this.pannel = ''
        this.$store.dispatch('abortRequestById', domainId)
      },

      async getDomainsAction() {
        
      },
      openTooltip(domainId){
        this.pannel = ''
        this.loading = true
        this.setSelectedDomainId(domainId)
        
        // Reset zone records
        this.setDomainDNSRecords({ domainId, records: [] })

        // Cancel any pending requests
        this.$store.dispatch('abortAllRequests')

        // Get zone DNS records, fresh
        this.$store.dispatch('getDomainRecords', domainId)
        .finally(() => {
          this.loading = false
        })

        //return on
      },
      async openTestPannel(domainId) {
        this.loading = true
        this.pannel = 'test'
        this.setSelectedDomainId(domainId)
        
        // Reset zone records and prepared urls list
        this.setDomainDNSRecords({ domainId, records: [] })
        this.setDomainTestingUrls(domainId)

        // Cancel any pending requests
        this.$store.dispatch('abortAllRequests')

        // Get zone DNS records, fresh
        await this.$store.dispatch('getDomainRecords', domainId)

        // Re-create testing urls from dns records, fresh
        this.setDomainTestingUrls(domainId)

        Promise.all(this.getDomainTestingUrlsById(domainId).map(url => {
          return this.$http.post(`${this.getProxyUrl}/test/url`, {
            "url": url.url
          }, { abort: { id: domainId } })
          .then(response => {
            url.code = !isNaN(response.code) ? Number(response.code) : response.code
            url.redirect = response.redirect

            return url
          })
          .catch(error => {
            url.code = 'error'
          })
        }))
        .finally(() => {
          this.loading = false
        })
      },

      urlStatusColor (value) {
        return (value < 400) ? 'green' : (value < 500) ? 'orange' : 'red'
      }
    },
    created () {
      this.$store.dispatch('getDomains')
    }
  }
</script>

<style>
.domain-status { font-size:12px; align-self:center; padding-left: 5px; line-height: normal; }

.menu-tooltip { position:relative ; z-index:1 }
.menu-tooltip:after {
  content: "";
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%; 
  height: 100%;  
  opacity: .4; 
  z-index: -1;
  background-color:black
}
</style>
