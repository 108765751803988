<template>
  <div>
    <BaseTitle
      hideRefresh="true"
      hideSearch="true"
    >
      <template v-slot:title>
        {{ creationMethod.toUpperCase() }} Domains
      </template>
      <template v-slot:actions>
        <v-col
          cols="auto"
          class="flex-grow-0 text-right"
        >
          <v-btn
            class="mx-2"
            color="gray"
            depressed
            @click="changeCreationMethod"
          >
          {{ creationMethod === 'add' ? 'import' : 'add' }}
          </v-btn>
          <v-btn-toggle
            v-model="selectedAccount"
            mandatory
          >
            <v-btn
              v-for="account in getUserMemberships"
              :key="account.id"
              :value="account"
              x-small
            >
            {{ account.name }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </template>
    </BaseTitle>

    <v-row>
      <v-col cols="12">
        <v-textarea
          v-if="creationMethod === 'add'"
          v-model="rawdomains"
          outlined
          label="Paste here list of domain names"
        />
        <v-file-input
          v-if="creationMethod === 'import'"
          v-model="rawfiles"
          small-chips
          multiple
          label="Files"
        ></v-file-input>

        <v-btn
          color="accent"
          depressed
          @click="parse"
        >
          Parse
        </v-btn>
        <v-btn
          color="secondary"
          class="ml-2"
          depressed
          @click="rawdomains = ''; rawfiles = []; parse()"
        >
          Clear
        </v-btn>
      </v-col>
      <v-col
        v-if="errors.length > 0"
        cols="12"
      >
      <v-alert
        v-for="(error, i) in errors"
        :key="`addDomainsError-${i}`"
        dense
        type="error"
      >
        {{ error }}
      </v-alert>
      </v-col>
      <v-col
        v-if="getShowProgress"
        cols="12"
      >
        <v-progress-linear
          indeterminate
          color="accent"
        ></v-progress-linear>
      </v-col>
      <v-col
        v-if="parseddomains.length > 0"
        cols="12"
      >
        <v-divider></v-divider>

        <v-data-table
          dense
          :headers="tableHeaders"
          :items="parseddomains"
          hide-default-footer
          disable-pagination
          disable-sort
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:item="{ item }">
            <tr
              @click="selectedRow = item.name"
            >
              <td class="text-h5 pa-3" nowrap valign="top" width="20%">
                <v-progress-circular
                  v-if="item.status === 'progress'"
                  class="mr-2"
                  size="12"
                  width="2"
                  indeterminate
                  color="secondary"
                />

                <v-icon
                  v-if="item.status === 'done'"
                  class="ma-2"
                  color="green"
                >
                  fas fa-check
                </v-icon>

                <v-icon
                  v-if="item.status === 'error'"
                  class="ma-2"
                  color="error"
                >
                  fas fa-exclamation-triangle
                </v-icon>

                <v-btn
                  v-if="item.exists || item.status === 'done'"
                  depressed
                  text
                  small
                  color="grey"
                  :href="`${getCloudflareDashboardUrl}/${selectedAccount.id}/${item.name}`"
                  target="_blank"
                >
                  {{ item.name }}

                  <v-icon
                    class="ml-2"
                    small
                    color="accent"
                  >
                    mdi-open-in-new
                  </v-icon>
                </v-btn>

                <span
                  v-else
                >
                  {{ item.name }}
                </span>

                <v-chip
                  v-if="item.exists && item.status !== 'done'"
                  color="error"
                >
                  ALREADY EXISTS
                </v-chip>

                <v-switch
                  v-if="item.exists || item.status === 'error'"
                  v-model="item.force"
                  label="Force re-create"
                  color="accent"
                  class="ma-0 mt-3 ml-3"
                  small
                ></v-switch>
              </td>
              <td
                v-if="item.status === 'done'"
                class="pa-3"
              >
                Nameservers: <v-chip
                  v-for="(ns, i) in item.result.name_servers"
                  :key="`${item.name}-ns-${i}`"
                  color="green"
                  small
                  class="mx-1"
                >
                  {{ ns }}
                </v-chip>
              </td>
              <td
                v-else
                class="pa-3"
              >
                <AddDomainsManual
                  v-if="creationMethod === 'add'"
                  :initialItem="item"
                  :selectedRow="selectedRow"
                />
                <AddDomainsImport
                  v-if="creationMethod === 'import'"
                  :initialItem="item"
                  :selectedRow="selectedRow"
                />
              </td>
            </tr>
          </template>
        </v-data-table>

        <v-btn
          v-if="sendThose.length > 0 && getSocketAllFinished !== 'progress'"
          color="accent"
          class="mt-3"
          depressed
          @click="start"
        >
          Start
        </v-btn>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
  import BaseTitle from '@/components/BaseTitle.vue'
  import AddDomainsManual from '@/components/AddDomainsManual.vue'
  import AddDomainsImport from '@/components/AddDomainsImport.vue'
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'AddDomainsPage',
    data: () => ({
      selectedAccount: {},
      creationMethod: 'add',
      rawdomains: '',
      rawfiles: [],
      parseddomains: [],
      selectedRow: '',
      tableHeaders: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Options',
          value: 'options'
        }
      ],
      errors: []
    }),
    components: {
      BaseTitle,
      AddDomainsManual,
      AddDomainsImport
    },
    computed: {
      ...mapGetters([
        'getProxyUrl',
        'getAuthHeaders',
        'getUserMemberships',
        'getLocalStorage',
        'getShowProgress',
        'getSocketAllFinished',
        'getCloudflareDashboardUrl',
      ]),
      sendThose () {
        return this.parseddomains.filter(pd => ((!pd.exists && pd.status !== 'done') || pd.force))
      }
    },
    methods: {
      ...mapMutations([
        'setShowProgress',
        'setSocketAllFinished'
      ]),
      changeCreationMethod () {
        this.creationMethod = (this.creationMethod === 'add' ? 'import' : 'add')
        this.$store.dispatch('setLocalStorage', { key: 'creationMethod', value: this.creationMethod })

        this.rawdomains = ''
        this.rawfiles = []
        this.parse()
      },
      parse () {
        this.setSocketAllFinished(false)
        this.setShowProgress(true)

        this.parseddomains = []
        this.errors = []

        this.$store.dispatch('setLocalStorage', { key: 'addDomainsRawInput', value: this.rawdomains })

        let parsePromise

        if (this.creationMethod === 'add') {
          parsePromise =  this.parseRawList()
        }

        if (this.creationMethod === 'import') {
          parsePromise = this.parseRawFiles()
        }

        parsePromise
        .then(temp => {
          if (temp.length > 0) {
            return this.$http.get(`${this.getProxyUrl}/zones/byname/${temp.map(t => t.name).join(',')}`, { abort: { id: `${Date.now()}` } })
            .then(response => {
              return response.result.map(ex => ex.name)
            })
            .then(tempExist => {
              this.parseddomains = temp.map(pd => {
                pd.exists = tempExist.indexOf(pd.name) > -1
                return pd
              })

              return true
            })
          } else {
            return Promise.reject('List Empty!')
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(()=>{
          this.setShowProgress(false)
        })
      },

      async parseRawFiles () {
        console.log(this.rawfiles)

        let tempList = this.rawfiles
        .filter(file => file.size > 0)
        .filter(file => file.type === 'text/plain')
        .filter(file => file.name.slice(file.name.length-4, file.name.length) === '.txt')
        .filter(file => /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/.test(file.name.slice(0, -4)))

        return await Promise.all(tempList.map(async file => {
          return {
            name: file.name.slice(0, -4),
            exists: false,
            status: null,
            account: this.selectedAccount?.id || 0,
            force: false,
            file: {
              name: file.name,
              type: file.type,
              size: file.size,
              content: await file.text()
            }
          }
        }))
      },

      async parseRawList () {
        let tempList = this.rawdomains.split('\n')
        .filter(pd => pd.trim().length > 0)
        .filter((value, index, self) => {
          return self.indexOf(value) === index
        })
        .filter(pd => /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/.test(pd))

        return tempList.map(pd => {
          return {
            name: pd.trim(),
            exists: false,
            dnsConfiguration: 0,
            status: null,
            ip: '',
            proxied: false,
            createWWWRecord: true,
            rules: [],
            account: this.selectedAccount?.id || 0,
            force: false
          }
        })
      },
      start () {
        this.$socket.off('create')
        this.$socket.off('create.error')

        this.setSocketAllFinished('progress')

        this.errors = []

        this.sendThose.forEach(pd => {
          pd.status = 'progress'
        })

        this.$socket.emit('toAll', {
          event: 'create',
          headers: this.getAuthHeaders,
          zones: this.sendThose
        })

        if(!this.$socket._callbacks.hasOwnProperty('$create')){
          this.$socket.on('create', data => {
            let parsedDomainIndex = this.parseddomains.findIndex(pd => {
              return pd.name === data.result.name
            })

            if (parsedDomainIndex > -1) {
              this.parseddomains[parsedDomainIndex].status = 'done'
              this.parseddomains[parsedDomainIndex].result = data.result
              this.parseddomains[parsedDomainIndex].force = false
            }
          })
        }

        if(!this.$socket._callbacks.hasOwnProperty('$create.error')){
          this.$socket.on('create.error', error => {
            this.errors.push(`[${error.id || 'all'}] ${error.error}`)

            let parsedDomainIndex = this.parseddomains.findIndex(pd => {
              return pd.name === error.id
            })

            if (parsedDomainIndex > -1) {
              this.parseddomains[parsedDomainIndex].status = 'error'
            }
          })
        }
      }
    },

    watch: {
      selectedAccount () {
        this.parseddomains.forEach(pd => {
          pd.account = this.selectedAccount?.id || 0
        })

        this.$store.dispatch('setLocalStorage', { key: 'addDomainsSelectedAccount', value: JSON.stringify(this.selectedAccount) })
      }
    },

    created () {
      this.$socket.off('create')
      this.$socket.off('create.error')

      this.rawdomains = this.getLocalStorage('addDomainsRawInput') || ''
      this.creationMethod = this.getLocalStorage('creationMethod') || 'add'
      this.selectedAccount = JSON.parse(this.getLocalStorage('addDomainsSelectedAccount')) || {}
      this.parse()
    }
  }
</script>

<style>
</style>