<template>
  <div>
    <BaseTitle
      hideRefresh="true"
      hideSearch="true"
    >
      <template v-slot:title>
        Test Domains
      </template>
    </BaseTitle>

    <v-row>
      <v-col cols="12">
        <v-textarea
          v-model="rawdomains"
          outlined
          label="Paste here list of domain names"
        />
        <v-btn
          color="accent"
          depressed
          @click="parseRawList"
        >
          Parse
        </v-btn>
        <v-btn
          color="secondary"
          class="ml-2"
          depressed
          @click="rawdomains = ''; parseRawList()"
        >
          Clear
        </v-btn>
      </v-col>
      <v-col
        v-if="parseddomains.length > 0"
        cols="12"
      >
        <v-divider></v-divider>

        <v-data-table
          dense
          :headers="tableHeaders"
          :items="parseddomains"
          hide-default-footer
          disable-pagination
          disable-sort
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-h5 pa-3" nowrap valign="top" width="40%">
                <v-progress-circular
                  v-if="item.status === 'progress'"
                  class="mr-2"
                  size="12"
                  width="2"
                  indeterminate
                  color="secondary"
                />

                <v-icon
                  v-if="item.status === 'done'"
                  class="ma-2"
                  color="green"
                >
                  fas fa-check
                </v-icon>

                <v-icon
                  v-if="item.status === 'error'"
                  class="ma-2"
                  color="error"
                >
                  fas fa-exclamation-triangle
                </v-icon>

                {{ item.name }}
              </td>
              <td class="pa-3">
                {{item.result?.http?.code || ''}} <br />
                {{item.result?.http?.redirect || ''}}
              </td>
              <td class="pa-3">
                {{item.result?.httpwww?.code || ''}} <br />
                {{item.result?.httpwww?.redirect || ''}}
              </td>
              <td class="pa-3">
                {{item.result?.https?.code || ''}} <br />
                {{item.result?.https?.redirect || ''}}
              </td>
              <td class="pa-3">
                {{item.result?.httpswww?.code || ''}} <br />
                {{item.result?.httpswww?.redirect || ''}}
              </td>
            </tr>
          </template>
        </v-data-table>

        <v-btn
          color="accent"
          class="mt-3"
          depressed
          @click="start"
        >
          Start
        </v-btn>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
  import BaseTitle from '@/components/BaseTitle.vue'
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'TestDomainsPage',
    data: () => ({
      rawdomains: '',
      parseddomains: [],
      tableHeaders: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'http',
          value: 'http'
        },
        {
          text: 'http+www',
          value: 'httpwww'
        },
        {
          text: 'https',
          value: 'https'
        },
        {
          text: 'https+www',
          value: 'httpswww'
        }
      ],
      errors: []
    }),
    components: {
      BaseTitle
    },
    computed: {
      ...mapGetters([
        'getProxyUrl',
        'getAuthHeaders',
        'getLocalStorage',
        'getShowProgress',
        'getSocketAllFinished'
      ]),
    },
    methods: {
      ...mapMutations([
        'setShowProgress',
        'setSocketAllFinished'
      ]),
      async parseRawList () {
        this.setSocketAllFinished(false)
        this.setShowProgress(true)

        this.parseddomains = []
        this.errors = []

        let tempList = this.rawdomains.split('\n')
        .filter(pd => pd.trim().length > 0)
        .filter((value, index, self) => {
          return self.indexOf(value) === index
        })
        .filter(pd => /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/.test(pd))

        this.parseddomains = tempList.map(pd => {
          return {
            name: pd.replace('Subdomains', '').replace('Subdomain', '').replace('(mit SSL)', '').trim(),
            result: {
              all: [],
              http: {
                code: '',
                redirect: ''
              },
              httpwww: {
                code: '',
                redirect: ''
              },
              https: {
                code: '',
                redirect: ''
              },
              httpswww: {
                code: '',
                redirect: ''
              },
              done: false
            },
            status: null
          }
        })

        this.setShowProgress(false)
      },
      start () {
        console.log('Start...')

        let tobetested = this.parseddomains.filter(domain => !domain.result.done).splice(0,20)

        if (tobetested.length === 0) {
          // done
          return
        }

        console.log('Starting:')
        console.log(tobetested.map(domain => domain.name).join(','))

        this.$http.post(`${this.getProxyUrl}/test/domain`, {
          "domain": tobetested.map(d => d.name)
        }, { abort: { id: 'test-domains' } })
        .then(this.parseResult)
      },

      parseResult (results) {
        this.parseddomains.forEach(domain => {
          domain.result.all = results.filter(r => {
            return r.url.indexOf(domain.name) > -1
          })

          let httpIndex = results.findIndex(r => {
            return r.url === `http://${domain.name}`
          })

          if (httpIndex > -1) {
            domain.result.http.code = results[httpIndex].code
            domain.result.http.redirect = results[httpIndex].redirect
          }

          let httpwwwIndex = results.findIndex(r => {
            return r.url === `http://www.${domain.name}`
          })

          if (httpwwwIndex > -1) {
            domain.result.httpwww.code = results[httpwwwIndex].code
            domain.result.httpwww.redirect = results[httpwwwIndex].redirect
          }

          let httpsIndex = results.findIndex(r => {
            return r.url === `https://${domain.name}`
          })

          if (httpsIndex > -1) {
            domain.result.https.code = results[httpsIndex].code
            domain.result.https.redirect = results[httpsIndex].redirect
          }

          let httpswwwIndex = results.findIndex(r => {
            return r.url === `https://www.${domain.name}`
          })

          if (httpswwwIndex > -1) {
            domain.result.httpswww.code = results[httpswwwIndex].code
            domain.result.httpswww.redirect = results[httpswwwIndex].redirect
          }

          if (domain.result.all.length > 0) {
            domain.result.done = true
          }
        })

        console.log(this.parseddomains)

        setTimeout(() => {
          console.log('Timeout passed...')
          this.start()
        }, 500)
      }
    },

    created () {}
  }
</script>

<style>
</style>