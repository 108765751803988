import Vue from 'vue'
import store from '../store'
import io from 'socket.io-client'
import eventBus from '@/lib/eventBus'

const socket = io(process.env.VUE_APP_SOCKET_SERVER, {
  withCredentials: false,
  autoConnect: false,
  reconnection: true
})

function handleError (error) {
  console.error(`[${error.event}] error:`)
  console.error(error)

  let domain = store.getters.getDomainById(error.id || 0)

  if (domain) {
    domain.busy = 'error'
  }

  eventBus.$emit('alertGlobal', {
    type: 'error',
    message: `[${domain && domain.name ? domain.name : error.id}]: ${error.error.toString()}`
  })
}

/**
 * Connection events
 */
socket.on('connect', () => {
  console.log('Connection')
})

socket.on('connect_error', () => {
  console.log('Connect Error')
})

socket.on('disconnect', () => {
  console.log('Disconnection')
})

socket.on('reconnect_attempt', () => {
  console.log('Reconnect Attempt')
})

socket.on('reconnect_error', () => {
  console.log('Reconnect Error')
})

/**
 * Errors events
 */

socket.on('error', (error) =>{
  console.error('[General] Socket error:')
  console.error(error)
})

socket.on('test.error', handleError)

socket.on('analytics.error', handleError)

/**
 * Success type events
 */

socket.on('done', (data) => {
  store.commit('setSocketAllFinished', 'done')
  return
})

socket.on('domain', (data) => {
  let domain = store.getters.getDomainById(data.id || 0)

  if (data.event === 'test') {
    domain.testingUrls = data.result
  }

  if (data.event === 'analytics') {
    store.commit('setAnalytics', {
      domainId: data.id,
      analytics: data.result
    })
  }

  if (data.event === 'create') {
    store.commit('addCreated', data.result)
  }
  
  if (domain) {
    domain.busy = 'done'
  }
  
})

Vue.prototype.$socket = socket