<template>
  <div>
    <v-row
      v-if="item.file && item.file.size > 0"
      class="pt-3 align-center"
      no-gutters
    >
      <v-col
        cols="12"
      >
        <v-textarea
          :value="item.file.content"
          readonly
        />
      </v-col>
      <v-col
        cols="12"
      >
        File size: {{ item.file.size }} bytes 
      </v-col>
      <v-col
        cols="12"
      >
        Lines: {{ item.file.content.split(/\r\n|\r|\n/).length }} 
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'AddDomainsImport',
    props: ['initialItem', 'selectedRow'],
    data: () => ({
      item: {}
    }),
    computed: {
    },
    methods: {},
    created () {
      this.item = this.initialItem
    }
  }
</script>

<style>
</style>