<template>
  <v-row id="header" align="center" justify="space-between" class="ma-0 my-3 flex-grow-0">
    <v-col cols="auto">
      <v-img
        :src="require('@/assets/Cloudflare_Logo.png')"
        class="my-1"
        contain
        height="40"
        width="40"
      />
    </v-col>
    <v-col cols="auto" class="header-nav-buttons flex-grow-1 text-right d-flex flex-row justify-end">
      <v-btn
        v-if="!$store.state.readOnlyMode"
        active-class="nav-active"
        text
        to="/"
      >
        Dashboard
      </v-btn>
      <v-btn
        active-class="nav-active"
        text
        to="/analytics"
      >
        Analytics
      </v-btn>
      <v-btn
        v-if="!$store.state.readOnlyMode"
        active-class="nav-active"
        text
        to="/add-domains"
      >
        Add Domains
      </v-btn>
      <v-btn
        text
        @click="$store.dispatch('logout')"
      >
        Logout
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BaseHeader',
    data: () => ({
      menu: []
    }),
  }
</script>

<style>
#header {
  border-bottom: solid 1px var(--v-main-border-base)
}
.header-nav-buttons > * { margin:0px 5px; }
.v-btn.nav-active { background-color:#ecf4ff; color:#003681; border: 1px solid #b9d6ff; border-radius: 12px; }
.v-btn.nav-active:before { background-color: transparent; }
</style>