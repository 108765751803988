import store from '../store'
import eventBus from '@/lib/eventBus'

const fetchController = {
  abortControllers: [],
  get (url, options) {
    return this.request(url, {
      method: 'GET'
    }, options)
  },
  post (url, body, options) {
    return this.request(url, {
      method: 'POST',
      body: body
    }, options)
  },
  delete (url, options) {
    return this.request(url, {
      method: 'DELETE'
    }, options)
  },
  put (url, body, options) {
    return this.request(url, {
      method: 'PUT',
      body: body
    }, options)
  },
  patch (url, body, options) {
    return this.request(url, {
      method: 'PATCH',
      body: body
    }, options)
  },
  request (url, requestConfig, options) {
    if (!requestConfig.headers) {
      requestConfig.headers = {}
    }

    if (store.getters.getSessionAuthType === 'token') {
      requestConfig.headers['Authorization'] = `Bearer ${store.getters.getSessionToken}`
    }

    if (store.getters.getSessionAuthType === 'apikey') {
      requestConfig.headers['X-Auth-Email'] = store.getters.getSessionEmail
      requestConfig.headers['X-Auth-Key'] = store.getters.getSessionApikey
    }

    if (requestConfig.body) {
      if (requestConfig.body.constructor === FormData) {
        requestConfig.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      } else {
        requestConfig.headers['Content-Type'] = 'application/json'
        requestConfig.body = JSON.stringify(requestConfig.body)
      }
    }
    
    if (options?.abort && options?.abort?.id) {
      let abortObject = {}

      const abortAlreadyExists = this.abortControllers.find(a => {
        return a.id === options.abort.id
      })

      if (!abortAlreadyExists) {
        abortObject.id = options.abort.id
        abortObject.url = url
        abortObject.controller = new AbortController()
        abortObject.signal = abortObject.controller.signal

        this.abortControllers.push(abortObject)
      } else {
        abortObject.signal = abortAlreadyExists.signal
      }

      requestConfig.signal = abortObject.signal
    }

    return fetch(url, requestConfig)
    .then(async response => {
      let data = await response.text()

      try {
        data = JSON.parse(data)
      } catch (err) {
          
      }

      if (response.status >= 400) {
        return Promise.reject(data)
      }
      
      return Promise.resolve(data)
    })
    .catch(error => {
      if (requestConfig.signal.aborted) {
        return
      }

      eventBus.$emit('alertGlobal', {
        type: 'error',
        message: error.toString()
      })

      return Promise.reject(error)
    })
  }
}

export default fetchController