<template>
  <div class="dashboard">
    <DomainsList />
  </div>
</template>

<script>
import DomainsList from '@/components/DomainsList.vue'

export default {
  name: 'DashboardPage',
  components: {
    DomainsList
  }
}
</script>
