const CryptoJS = require("crypto-js")

module.exports = {
  urlVariants (domain) {
    domain = domain.replace('http://', '').replace('https://', '').replace('www.', '');

    return [
      {url: `http://${domain}`, code: '', redirect: ''},
      {url: `http://www.${domain}`, code: '', redirect: ''},
      {url: `https://${domain}`, code: '', redirect: ''},
      {url: `https://www.${domain}`, code: '', redirect: ''},
    ]
  },

  crypto (value) {
    return CryptoJS.AES.encrypt(value, process.env.VUE_APP_CRYPTO_KEY).toString()
  },

  bytesToSize (bytes) {
    // bytes devided by 1000 and not by 1024 because this is how Cloudflare calculate it!

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]}`
    return `${(bytes / (1000 ** i)).toFixed(1)} ${sizes[i]}` 
  }
}