import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cloudflare: {
      dashboard_url: process.env.VUE_APP_CLOUDFLARE_DASHBOARD
    },
    api:{
      proxy: process.env.VUE_APP_PROXY_URL
    },
    session: {
      authorized: false,
      email: '',
      apikey: '',
      token: '',
      user: null
    },
    domains: [],
    domains_per_page: process.env.VUE_APP_DOMAINS_PER_PAGE || 20,
    domains_page: 1,
    domains_pagination: {},
    created: [],
    selectedDomainId: '',
    selectedDomain: null,
    socketAllFinished: false,
    showProgress: false,
    search: '',
    readOnlyMode: process.env.VUE_APP_READONLY === 'true'
  },
  getters: {
    getProxyUrl: state => state.api.proxy,
    getSessionEmail: state => state.session.email,
    getSessionApikey: state => state.session.apikey,
    getSessionToken: state => state.session.token,
    getSessionAuthType: (state) => {
      return state.session.token ? 'token' : ((state.session.email && state.session.apikey) ? 'apikey' : false)
    },
    getAuthorized: state => state.session.authorized,
    getUserDetails: state => state.session.user,
    getUserAccounts: state => state.session.user.organizations,
    getUserMemberships: state => state.session.user.memberships,
    getCloudflareDashboardUrl: state => state.cloudflare.dashboard_url,
    getDomains: state => state.domains,
    getDomainsPerPage: state => state.domains_per_page,
    getDomainsPage: state => state.domains_page,
    getDomainsPagination: state => state.domains_pagination,
    getCreated: state => state.created,
    getSelectedDomainId: state => state.selectedDomainId,
    getSelectedDomain: state => state.selectedDomain,
    getDomainById: (state) => (domainId) => {
      return state.domains.find(domain => domain.id === domainId)
    },
    getDomainRecordsById: (state, getters) => (domainId) => {
      return getters.getDomainById(domainId)?.dnsRecords || []
    },
    getAnalyticsById: (state, getters) => (domainId) => {
      return getters.getDomainById(domainId)?.analytics || []
    },
    getDomainTestingUrlsById: (state, getters) => (domainId) => {
      return getters.getDomainById(domainId)?.testingUrls || []
    },
    getSocketAllFinished: state => state.socketAllFinished,
    getShowProgress: state => state.showProgress,
    getSearch: state => state.search,
    getAuthHeaders: (state, getters) => {
      const headers = {}

      if (getters.getSessionAuthType === 'token') {
        headers['authorization'] = `Bearer ${getters.getSessionToken}`
      }

      if (getters.getSessionAuthType === 'apikey') {
        headers['x-auth-email'] = getters.getSessionEmail
        headers['x-auth-key'] = getters.getSessionApikey
      }

      return Vue.prototype.$utils.crypto(JSON.stringify(headers))
    },
    getLocalStorage: () => (key) => {
      return window.localStorage.getItem(key)
    }
  },
  mutations: {
    setSessionEmail (state, value) {
      state.session.email = value
    },
    setSessionApikey (state, value) {
      state.session.apikey = value
    },
    setSessionToken (state, value) {
      state.session.token = value
    },
    setAuthorized (state, value) {
      state.session.authorized = value
    },
    setUserDetails (state, value) {
      state.session.user = value
    },
    setDomains (state, value) {
      state.domains = value
    },
    setDomainsPage (state, value) {
      state.domains_page = value
    },
    increaseDomainsPage (state, value) {
      if (state.domains_page < state.domains_pagination.total_pages) {
        state.domains_page += 1
      }
    },
    decreaseDomainsPage (state, value) {
      if (state.domains_page > 1) {
        state.domains_page -= 1
      }
    },
    setDomainsPagination (state, value) {
      state.domains_pagination = value
    },
    addCreated (state, value) {
      state.created.push(value)
    },
    setSelectedDomainId (state, value) {
      state.selectedDomainId = value

      const domain = state.domains.find(domain => {
        return domain.id === value
      })
      
      if (domain) {
        state.selectedDomain = domain
      }
    },
    setDomainDNSRecords (state, payload) {
      const domain = state.domains.find(domain => {
        return domain.id === payload.domainId
      })
      
      if (domain) {
        domain.dnsRecords = payload.records
      }
    },
    setAnalytics (state, payload) {
      const domain = state.domains.find(domain => {
        return domain.id === payload.domainId
      })
      
      if (domain) {
        domain.analytics = payload.analytics
      }
    },
    setDomainTestingUrls (state, domainId) {
      const domain = state.domains.find(domain => {
        return domain.id === domainId
      })
      
      if (domain) {
        domain.testingUrls = domain.dnsRecords.filter(record => {
          return ['A', 'AAAA', 'NS'].indexOf(record.type) > -1 && record.name !== domain.name
        })
        .map(record => {
          return Vue.prototype.$utils.urlVariants(record.name)
        })
        .flat()

        domain.testingUrls.push(...Vue.prototype.$utils.urlVariants(domain.name))
      }
    },

    setSocketAllFinished (state, value) {
      state.socketAllFinished = value
    },
    setShowProgress (state, value) {
      state.showProgress = value
    },
    setSearch (state, value) {
      state.search = value
    }
  },
  actions: {
    setLocalStorage ({}, payload) {
      window.localStorage.setItem(payload.key, payload.value)
    },

    abortAllRequests ({}) {
      Vue.prototype.$http.abortControllers.forEach((request, i) => {
        request.controller.abort('Cancelled')
        Vue.prototype.$http.abortControllers.splice(i, 1)
      })
    },

    abortRequestById ({}, id) {
      const abortIndex = Vue.prototype.$http.abortControllers.findIndex(a => {
        return a.id === id
      })

      if (abortIndex > -1) {
        Vue.prototype.$http.abortControllers[abortIndex].controller.abort('Cancelled')
        Vue.prototype.$http.abortControllers.splice(abortIndex, 1)
      }
    },

    authorize ({ commit, getters }, credentials) {
      commit('setSessionEmail', credentials.email)
      commit('setSessionApikey', credentials.apikey)
      commit('setSessionToken', credentials.token)

      return Vue.prototype.$http.get(`${getters.getProxyUrl}/user`)
      .then((user) => {
        if (user.result && user.result.id) {
          return Vue.prototype.$http.get(`${getters.getProxyUrl}/memberships`)
          .then(memberships => {
            user.result.memberships = memberships.result.map(m => {
              return m.account
            })

            commit('setAuthorized', true)
            commit('setUserDetails', user.result)
            Vue.prototype.$socket.connect()
            router.push('/')
          })
        }

        return Promise.reject('Something went wrong. Received user details, but user and user.id is falsy!')
      })
      .catch((error) => {
        commit('setAuthorized', false)
      })
    },

    logout (context) {
      context.commit('setSessionEmail', '')
      context.commit('setSessionApikey', '')
      context.commit('setSessionToken', '')
      context.commit('setDomains', [])

      context.commit('setAuthorized', false)

      Vue.prototype.$socket.disconnect()

      router.push('/login')
    },
    getDomains (context) {
      context.commit('setDomains', [])

      // Cancel any pending requests
      context.dispatch('abortAllRequests')

      context.commit('setSelectedDomainId', null)
      context.commit('setShowProgress', true)
      context.commit('setSocketAllFinished', false)

      let queryArray = []

      queryArray.push(`page=${context.getters.getDomainsPage}`)
      queryArray.push(`per_page=${context.getters.getDomainsPerPage}`)

      if (context.getters.getSearch && context.getters.getSearch.length > 0) {
        queryArray.push(`filter[name]=${context.getters.getSearch}`)
      }

      return Vue.prototype.$http.get(`${context.getters.getProxyUrl}/zones${queryArray.length > 0 ? `?${queryArray.join('&')}` : ''}`)
      .then((domains) => {
        context.commit('setDomainsPagination', domains.result_info)

        domains = domains.result.map(domain => {
          domain.dnsRecords = []
          domain.testingUrls = []
          domain.busy = false
          domain.analytics = {}

          return domain
        })

        context.commit('setDomains', domains)
        return domains
      })
      .finally(() =>{
        context.commit('setShowProgress', false)
      })
    },

    getDomainRecords (context, domain) {
      let domainId = domain

      if (typeof domain === 'object' && domain.hasOwnProperty('id')){
        domainId = domain.id
      }

      return Vue.prototype.$http.get(`${context.getters.getProxyUrl}/zones/${domainId}/dns_records`, { abort: { id: domainId } })
      .then((records) => {
        records = records?.result || []

        context.commit('setDomainDNSRecords', {
          domainId,
          records
        })

        return records
      })
    },

    testAll (context) {
      context.commit('setSocketAllFinished', 'progress')

      Vue.prototype.$socket.emit('toAll', {
        event: 'test',
        search: context.getters.getSearch,
        headers: context.getters.getAuthHeaders
      })

      context.getters.getDomains.forEach(domain => {
        domain.busy = 'progress'
      })
    },

    analytics (context) {
      context.commit('setSocketAllFinished', 'progress')

      Vue.prototype.$socket.emit('toAll', {
        event: 'analytics',
        query: {
          page: context.getters.getDomainsPage,
          per_page: context.getters.getDomainsPerPage,
          filter: { name: context.getters.getSearch },
        },
        headers: context.getters.getAuthHeaders
      })

      context.getters.getDomains.forEach(domain => {
        domain.busy = 'progress'
      })
    },

    async csvTestAllDownload (context) {
      const domains = context.getters.getDomains

      let csv = 'data:text/csv;charset=utf-8,'
      csv += 'domain_name;url;status;redirect;timestamp\r\n'

      const dateObject = new Date()
      const timestamp = `${dateObject.getDate()}_${dateObject.getMonth() + 1}_${dateObject.getFullYear()}-${dateObject.getHours()}-${dateObject.getMinutes()}`

      domains.forEach(domain => {
        
        domain.testingUrls.forEach(url => {
          csv += `${domain.name};${url.url};${url.code};${url.redirect || ''};${url.timestamp}\r\n`
        })
      })

      const encodedUriCsv = encodeURI(csv)

      const csvLink = document.createElement('a')
      csvLink.setAttribute('href', encodedUriCsv)
      csvLink.setAttribute('download', `${timestamp}_zones_test.csv`)
      csvLink.setAttribute('data-timestamp', timestamp)
      document.body.appendChild(csvLink)

      csvLink.click()

      setTimeout(() => {
        csvLink.remove()
      }, 5000)
    },
    async csvAnalyticsAllDownload (context, options) {
      const domains = context.getters.getDomains

      let csv = 'data:text/csv;charset=utf-8,'
      csv += 'domain_name;unique_visitors (24h);total_requests (24h);total_data_served (24h);unique_visitors (30d);total_requests (30d);total_data_served (30d)\r\n'

      domains.forEach(domain => {
        if (domain.analytics && domain.analytics['24h'] && domain.analytics['30d']) {
          csv += `${domain.name};\
          ${domain.analytics['24h'].unique_visitors};\
          ${domain.analytics['24h'].total_requests};\
          ${options.bytesFormatted ? Vue.prototype.$utils.bytesToSize(domain.analytics['24h'].total_data_served) : domain.analytics['24h'].total_data_served};\
          ${domain.analytics['30d'].unique_visitors};\
          ${domain.analytics['30d'].total_requests};\
          ${options.bytesFormatted ? Vue.prototype.$utils.bytesToSize(domain.analytics['30d'].total_data_served) : domain.analytics['30d'].total_data_served}\r\n`
        } else {
          csv += `${domain.name};\
          error\r\n`
        }
      })

      const dateObject = new Date()
      const timestamp = `${dateObject.getDate()}_${dateObject.getMonth() + 1}_${dateObject.getFullYear()}-${dateObject.getHours()}-${dateObject.getMinutes()}`

      const encodedUriCsv = encodeURI(csv)

      const csvLink = document.createElement('a')
      csvLink.setAttribute('href', encodedUriCsv)
      csvLink.setAttribute('download', `${timestamp}_zones_analytics.csv`)
      csvLink.setAttribute('data-timestamp', timestamp)
      document.body.appendChild(csvLink)

      csvLink.click()

      setTimeout(() => {
        csvLink.remove()
      }, 5000)
    }
  },
  modules: {
  }
})
