import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Analytics from '../views/Analytics.vue'
import AddDomains from '../views/AddDomains.vue'
import TestDomains from '../views/TestDomains.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    redirect: store.state.readOnlyMode ? { name: 'analytics' } : null
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: Analytics
  },
  {
    path: '/add-domains',
    name: 'addDomains',
    component: AddDomains,
    redirect: store.state.readOnlyMode ? { name: 'analytics' } : null
  },
  {
    path: '/test-domains',
    name: 'testDomains',
    component: TestDomains,
    redirect: store.state.readOnlyMode ? { name: 'analytics' } : null
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.getters.getAuthorized) {
    return next({ name: 'login' })
    //return '/login'
  }

  next()
})

export default router
